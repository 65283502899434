// =============================================================================
// LAYOUT MODULE CONTROLLER
// =============================================================================

( function () {
    'use strict';

    angular
        .module( 'app.layout' )
        .controller( 'layoutCtrl', layoutCtrl );

    layoutCtrl.$inject = [ '$http', '$scope', '$interval' ];

    /* @ngInject */
    function layoutCtrl( $http, $scope, $interval ) {
        /* jshint validthis: true */
        var layoutVM = this;
        $scope.date = new Date();
        var tick = function () {
            $scope.clock = Date.now();
        };
        tick();
        $interval( tick, 1000 );
        console.log( 'Loading Layout Controller...' );

    }
} )();
