( function () {
    'use strict';

    angular
        .module( 'app' )
        .constant( 'ENV', {
          locations: [
              {
                  name: 'Casa de Cultura a Studentilor',
                  link: 'default'
          },
              {
                  name: 'Calea Floresti',
                  link: 'floresti'
          }
        ]
        } )
        .constant( '_', window._ );
} )();
