// =============================================================================
// HOME CONTROLLER
// =============================================================================

( function () {
    'use strict';

    angular
        .module( 'app.home' )
        .controller( 'homeCtrl', homeCtrl );

    homeCtrl.$inject = [ '$scope', '$interval', '$http', '$stateParams', 'ENV' ];

    /* @ngInject */
    function homeCtrl( $scope, $interval, $http, $stateParams, ENV ) {
        /* jshint validthis: true */
        var homeVM = this;
        homeVM.location = $stateParams.location;
        $scope.currentLocation = _.find( ENV.locations, {
            link: homeVM.location || 'default'
        } );
        $scope.getCurrency = function () {
            return $http.get( 'getCurrency.php' + ( homeVM.location ? '?location=' + homeVM.location : '?location=default' ) ).then( function ( response ) {
                console.log( response.data );
                $scope.currency = response.data;
            } );
        };
        $scope.getCurrency();
        $interval( function () {
            $scope.getCurrency();
        }, 60000 );
        console.log( 'Loading Home Controller...' );
    }
} )();
