// =============================================================================
// APP ROUTER / SET PAGE ROUTES
// =============================================================================

( function () {
    'use strict';

    angular
        .module( 'app' )
        .config( Routes );

    Routes.$inject = [ '$stateProvider', '$urlRouterProvider', '$locationProvider' ];

    /* @ngInject */
    function Routes( $stateProvider, $urlRouterProvider, $locationProvider ) {
        // For any unmatched url, redirect to /home
        $urlRouterProvider.otherwise( '/curs/' );
        // $locationProvider.html5Mode( true );
        $stateProvider
            .state( '404', {
                url: '/404',
                templateUrl: 'views/error/404.html'
            } );
        console.log( 'Loading Routes...' );
    }
} )();
