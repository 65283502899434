( function () {
    'use strict';
    angular
        .module( 'app', [
          'ui.router', // Angular UI Route
          'ngAnimate',
          'ngResource',
          'ngCookies', // Angular Cookies
          'ngMaterialize',
          'restangular',
          'angularLazyImg',
          'LocalStorageModule',
          'app.home', // APP HOME Module
          'app.layout'
      ] );
} )();
