// =============================================================================
// HOME MODULE
// =============================================================================

( function() {
    'use strict';

    angular
        .module( 'app.home', [] );
} )();
