// =============================================================================
// APP CONFIG / SET PAGE ROUTES
// =============================================================================

( function () {
    'use strict';

    angular
        .module( 'app' )
        .config( Config );

    Config.$inject = [ '$httpProvider', '$sceProvider', 'RestangularProvider', 'ENV', 'lazyImgConfigProvider', 'localStorageServiceProvider' ];

    /* @ngInject */
    function Config( $httpProvider, $sceProvider, RestangularProvider, ENV, lazyImgConfigProvider, localStorageServiceProvider ) {
        // var scrollable = document.querySelector( '#scrollable' );
        // lazyImgConfigProvider.setOptions( {
        //     offset: 100, // how early you want to load image (default = 100)
        //     errorClass: 'error', // in case of loading image failure what class should be added (default = null)
        //     successClass: 'success', // in case of loading image success what class should be added (default = null)
        //     onError: function ( image ) {}, // function fired on loading error
        //     onSuccess: function ( image ) {}, // function fired on loading success
        //     container: angular.element( scrollable ) // if scrollable container is not $window then provide it here
        // } );
        // localStorageServiceProvider
        //     .setPrefix( 'goldbox' )
        //     .setStorageCookie( 45, '/' )
        //     .setStorageCookieDomain( '' )
        //     .setNotify( true, true );
        console.log( 'Loading Config...' );
        // RestangularProvider.setBaseUrl( ENV.apiEndPoint );
        // $httpProvider.interceptors.push('errorInterceptor');
        // $httpProvider.interceptors.push('resourceInterceptor');
    }
} )();
