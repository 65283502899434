// =============================================================================
// LAYOUT (Main Module)
// =============================================================================

( function () {
    'use strict';

    angular
        .module( 'app.layout', [] );
} )();
