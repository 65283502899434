// =============================================================================
// APP RUN
// =============================================================================

( function () {
    'use strict';

    angular
        .module( 'app' )
        .run( Run );

    Run.$inject = [ '$rootScope' ];

    /* @ngInject */
    function Run( $rootScope ) {
        $rootScope._ = window._;
        console.log( 'Loading Run...' );
    }
} )();
